const print = () => {
    window.print();
}

const  dateOnly = (date) => {
    return new Date(date + 'T00:00:00').toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
    

const dateTime = (rawDate) => {
    const date = new Date(rawDate);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}

const dateTimeShort = (rawDate) => {
    const date = new Date(rawDate);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}


const daysAgo = (number = 0) => {
    const today = new Date();
    today.setDate(today.getDate() - number);
    today.setHours(0,0,0,0);

    return today;
}

const toMoney = (num) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(num);
}

const currency = 'Ksh.';

const today = new Date();
today.setHours(23, 59, 59, 0);
const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
const appName = 'simpos';
const slogan = 'Real-Time Sales, Real-Time Success'
const appContactNum = '+254799088611';

const signUp = () => {
    window.location.href = import.meta.env.VITE_POS_SIGNUP_URL;
}

const signIn = () => {
    window.location.href = import.meta.env.VITE_POS_SIGNIN_URL;
}
export default { dateOnly, print, dateTime, dateTimeShort, currency, daysAgo, maxDate, toMoney, appName, slogan, appContactNum, signIn, signUp }